import SvgIcon from '@mui/material/SvgIcon';

function WindowsIcon() {
  return (
    <SvgIcon viewBox='0 0 30 30'>
      <path d='M13.875 7.719l-2.281 7.906s-2.594-1.813-4.5-1.625c-1.875 0.188-4.406 1.125-4.406 1.125l2.313-7.969s4.781-2.563 8.875 0.563zM12.813 16.375l2.281-7.844c0.688 0.469 1.906 1.313 3.875 1.563 1.969 0.219 5.031-1.031 5.031-1.031l-2.281 7.906c-0.344 0.125-1.781 0.938-4.406 1.094-2.656 0.156-4.5-1.688-4.5-1.688zM0 24.469l2.281-7.969s2.563-1.188 4.719-1.031c2.125 0.125 3.844 1.313 4.188 1.594l-2.281 7.969s-2.625-1.719-4.438-1.563-3.406 0.469-4.469 1zM10.125 25.719l2.281-7.906s2.438 1.594 4.031 1.531c1.594-0.031 2.688-0.125 4.844-0.969h0.031c-0.156 0.406-2.281 7.844-2.281 7.844s-2.5 1.188-4.813 1.063c-2.281-0.094-3.656-1.406-4.094-1.563z'></path>{' '}
    </SvgIcon>
  );
}

export default WindowsIcon;
