import SvgIcon from '@mui/material/SvgIcon';

function SubtestsIcon() {
  return (
    <SvgIcon viewBox='0 0 18 18'>
      <path d='M1.5707 0.971436C1.90207 0.971436 2.1707 1.24006 2.1707 1.57144V4.97144H4.9707V4.28572C4.9707 3.40207 5.68705 2.68572 6.5707 2.68572H12.5707C13.4544 2.68572 14.1707 3.40207 14.1707 4.28572V6.85715C14.1707 7.7408 13.4544 8.45715 12.5707 8.45715H6.5707C5.68705 8.45715 4.9707 7.74081 4.9707 6.85715V6.17144H2.1707V13.5429H4.39927V12.8572C4.39927 11.9735 5.11562 11.2571 5.99927 11.2571H15.4278C16.3115 11.2571 17.0278 11.9735 17.0278 12.8571V15.4286C17.0278 16.3122 16.3115 17.0286 15.4278 17.0286H5.99928C5.11562 17.0286 4.39927 16.3122 4.39927 15.4286V14.7429H1.5707C1.23933 14.7429 0.970703 14.4742 0.970703 14.1429V1.57144C0.970703 1.24006 1.23933 0.971436 1.5707 0.971436ZM5.59927 14.1436V15.4286C5.59927 15.6495 5.77836 15.8286 5.99928 15.8286H15.4278C15.6488 15.8286 15.8278 15.6495 15.8278 15.4286V12.8571C15.8278 12.6362 15.6488 12.4571 15.4278 12.4571H5.99927C5.77836 12.4571 5.59927 12.6362 5.59927 12.8572V14.1421C5.59927 14.1424 5.59928 14.1426 5.59928 14.1429C5.59928 14.1431 5.59927 14.1434 5.59927 14.1436ZM6.5707 3.88572C6.34979 3.88572 6.1707 4.06481 6.1707 4.28572V6.85715C6.1707 7.07806 6.34979 7.25715 6.5707 7.25715H12.5707C12.7916 7.25715 12.9707 7.07806 12.9707 6.85715V4.28572C12.9707 4.06481 12.7916 3.88572 12.5707 3.88572H6.5707Z'></path>{' '}
    </SvgIcon>
  );
}

export default SubtestsIcon;
